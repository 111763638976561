.testimonials-section { 
  background-color: black;
  padding: 100px 0 70px;
  height: 600px;
}

.section-title h2 {
  font-size: 28px; /* Smaller font size */
  font-weight: 700;
  color: white;
  margin-bottom: 30px;
  max-width: 400px;
  width: 100%;
  margin: 0px auto 30px;
}

.mySwiper {
  padding-top: 50px;
  padding: 50px !important;
}

.swiper-slide {
  width: 200px;
  background-color: transparent;
  border-radius: 10px;
  background-size: cover;
  /* border: 1px solid white; */
  /* text-align: center; */
  /* background-image: url(./assets/slideBG.png); */
  color: #ffffff;
}

.testimonial-item {
  background-color: #fff;
  padding: 20px; /* Smaller padding */
  min-height: 80px !important;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  position: relative;
  margin: 0px auto;
  transform-origin: center;
  max-width: 500px;
  width: 100%;
}

.testimonial-content {
  font-size: 12.5px; /* Smaller font size */
  color: #666;
  min-height: 100px;
  line-height: 1.4;
  margin-bottom: 20px;
}

.testimonial-author {
  display: flex;
  align-items: center;
}

.author-details {
  margin-top: 10px;
}

.testimonial-author img {
  width: 70px; /* Smaller image size */
  height: 70px; /* Smaller image size */
  border-radius: 50%;
  margin-right: 20px;
}

.author-details h4 {
  font-size: 18px; /* Smaller font size */
  font-weight: 600;
  color: #333;
  margin-bottom: 5px;
}

.author-details p {
  font-size: 14px; /* Smaller font size */
  color: #666;
}

/* Responsive adjustments */
@media (min-width: 768px) {
  .testimonial-item {
    /* padding: 30px; */
  }
}

@media (min-width: 1024px) {
  .testimonial-item {
    /* padding: 40px; */
  }
}

.stars {
  color: #f39c12;
  font-size: 18px; /* Smaller font size */
}


.section-title {
position: relative;
}

.testimonial-logo {
width: 100px;
object-fit: cover;
height: 100px;
}

.testimonial-logo:first-of-type {
position: absolute;
top: -60px;
right: 350px;
transform: rotate(-95deg);
}

.testimonial-logo:last-of-type {
position: absolute;
left: 320px;
top: 40px;
transform: rotate(90deg);
}


.lesThanActive,
.moreThanActive {
opacity: 0.3;

}

.testimonial-arrows {
display: flex;
justify-content: space-between;
align-items: center;
position: relative;
top: -140px;
}

.testimonial-arrows svg {
width: 50px;
height: 50px;
color: white;
}

.swiper-button-prev ,
.swiper-button-next {
  color:white !important;
}

@media (max-width:1200px) {
  .testimonial-logo:first-of-type {
    top: -40px;
    right: 240px;
    transform: rotate(-88deg);
    }
    
    .testimonial-logo:last-of-type {
    left: 240px;
    top: 40px;
    transform: rotate(90deg);
    }
}

@media (max-width:993px) {
  .testimonial-logo:first-of-type {
    right: 130px;
  }

  .testimonial-logo:last-of-type {
    left:130px;
  }
}

@media (max-width:768px) {
  .testimonial-logo:first-of-type {
    right: 50px;
  }

  .testimonial-logo:last-of-type {
    left:40px;
  }
}

@media (max-width:520px) {
  .testimonial-logo:first-of-type {
    right: 40px;
  }

  .testimonial-logo:last-of-type {
    left:30px;
  }
}

@media (max-width:500px) {
  .testimonial-logo:first-of-type {
    right: 30px;
  }

  .testimonial-logo:last-of-type {
    left:18px;
  }

  .testimonial-item {
    flex-direction: column;
    text-align: center;
  }

  .testimonial-item img {
    margin: auto;
  }
}

@media (max-width:480px) {
  .testimonial-logo:first-of-type {
    right: 15px;
  }

  .testimonial-logo:last-of-type {
    left:10px;
  }
}

@media (max-width:460px) {
  .testimonial-logo:first-of-type {
    right: 10px;
  }

  .testimonial-logo:last-of-type {
    left:5px;
  }
}


@media (max-width:455px) {
  .testimonial-logo:first-of-type {
    right: 5px;
  }

  .testimonial-logo:last-of-type {
    left:0px;
  }
}

@media (max-width:440px) {
  .testimonial-logo:first-of-type {
    right: -10px;
  }

  .testimonial-logo:last-of-type {
    left:-20px;
  }
}

@media (max-width:400px) {
  .section-title h2 {
    font-size: 23px;
    max-width: 300px;
    width: 100%;
  }

  .testimonial-logo:first-of-type {
    right: -10px;
  }

  .testimonial-logo:last-of-type {
    left:-10px;
    top: 40px;
    transform: rotate(88deg);
  }
}

@media (max-width:361px) {
  .testimonial-logo:first-of-type {
    right:-15px;
    top: -50px;
  }

  .testimonial-logo:last-of-type {
    left:-20px;
    top: 40px;
  }
}