.services-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    /* background-color: #000; */
    padding: 20px;
    box-sizing: border-box;
    color: #fff;
  }
  
  .services-column {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 20px 0;
  }
  
  .service-box {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .service-image img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 20px;
  }
  
  .service-title h3 {
    font-size: 14px;
    font-weight: 600;
    color: #000;
    margin: 0;
  }
  
  @media (max-width: 768px) {
    .services-column {
      flex: 1 1 100%;
    }
  }
  