.installation-section .form-wrapper {
  /* margin-top: 50px; */
  text-align: center;
}

.installation-section .form-title {
  font-size: 2em;
  margin-bottom: 20px;
  color: #333;
}

.installation-section .contact-form {
  display: inline-block;
  text-align: left;
  max-width: 600px;
  width: 100%;
}

.installation-section .form-group {
  margin-bottom: 20px;
}

.installation-section .form-group label {
  display: block;
  font-size: 1em;
  color: #666;
  margin-bottom: 5px;
}

.installation-section .form-group input {
  width: 100%;
  padding: 10px;
  font-size: 1em;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.installation-section .btn-submit {
  display: inline-block;
  padding: 10px 20px;
  font-size: 1em;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.installation-section .btn-submit:hover {
  background-color: #0056b3;
}

.proposalshape {
  margin-top: 20px;
  margin-bottom: 0 ;
  height: 300px;
  width: 100%;
  background-color: black;
  color: white;
  display: flex;
  padding-top: 10px;
  justify-content: center;
  color: rgb(255, 238, 0);
  /* align-items: center; */
  clip-path: polygon(100% 0, 100% 100%, 51% 66%, 0% 100%, 0 43%, 0% 0%);
}

.signature-component{
  margin-left: 90px !important;
}

.toggle-buttons button{
  padding: 0 !important;
}

.sigCanvas{
  border: 2px dashed black;
  margin-top: 10px;
}


.installation-section{
  padding-top: 10px !important;
}