.login_header_container{
  width:100%;
  background-color: #141414;
  position: relative;
  padding: 10px 40px;
  
}

.login_header_container .login_header_conent{
  display: flex;
  align-items: center;
  justify-content: space-between;
}


.login_header_container .login_header_conent > div{
  width: 140px;
}

.login_header_container .login_header_conent > div img{
  width: 100%;
}


.hl_right_side > .chat_board_btn{
  padding: 3px 6px;
  display: flex;
  align-items: center;
  gap: 5px;
  background-color: var(--app-color);
  color: #fff;
  border-radius: 4px;
  transition: .3s ease;
  cursor: pointer;
}

.hl_right_side .chat_board_btn:hover{
  background-color: var(--app-dark-color);
}
