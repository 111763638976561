.enhancify-containter {
  background-image: url(../../../assets/images/rahme.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  min-height: 220vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ehnacify-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 0px;
}

.ehnacify-content .enhancify-logo {
  width: 400px;
}

.enhancify-content-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.enhancify-content-box p {
  color: white;
  padding: 0px;
  margin: 0px;
}

.enhancify-content-box h5 {
  color: #e50414;
  margin: 0px;
  padding: 0px;
}

.enhancify-img {
  width: 400px;
  object-fit: cover;
  margin-top: 20px;
}

@media (max-width: 1255px) {
  .enhancify-containter {
    min-height: 240vh;
  }

  .enhancify-img {
    width: 350px;
    object-fit: cover;
    margin-top: 20px;
  }
}

@media (max-width: 600px) {
  .ehnacify-content .enhancify-logo {
    width: 300px;
  }

  .enhancify-img {
    width: 300px;
  }
}

@media (max-width: 560px) {
  .enhancify-containter {
    background-image: url(../../../assets/images/rahme2.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    /* min-height: 220vh; */
    /* display: flex; */
    /* justify-content: center; */
    /* align-items: center; */
  }
}

@media (max-width: 430px) {
  .ehnacify-content .enhancify-logo {
    width: 250px;
  }
}

@media (max-width: 430px) {
  .enhancify-img {
    width: 250px;
  }

  .enhancify-containter {
    min-height: 100vh !important;
  }
}

@media (max-width: 415px) {
  .enhancify-img {
    width: 250px;
  }

  .enhancify-containter {
    min-height: 130vh !important;
  }
}

.monthly-payment-calculator {
  position: relative !important;
  top: 0 !important;
  left: 0 !important;
  max-width: 100% !important;
  width: min(300px, 100%) !important;
}
 