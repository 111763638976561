.real-state-page-container {
    /* position: relative; */
    /* top: -300px;
    z-index: -2; */
    background-color: black;
    padding: 30px 0px;
 }

 
  .real-state-content-container {
     max-width: 600px;
     width: 100%;
     margin: 0px auto;
     padding: 0px auto 20px;
     text-align: center;
     display: flex;
     flex-direction: column;
     gap: 20px;
  }
 
 .real-state-content-container p{
     color: #C12D29;
     margin: 0px;
     padding: 0px;
     font-size: 18px;
 }
 
 .real-state-content-container h3 {
     color: white;
     margin:0px;
     padding: 0px;
     font-size: 18px;
 }
 
 .real-state-img {
   width: 100%;
   height: 400px;
   position: relative;
   top: -400px;
 }
