.login_page_container{
  width: 100%;
  height: 100vh;
  background-image: url("../../assets/images/realty_1.jpeg");
  background-size: cover;
  /* background-position: center center; */
  background-repeat: no-repeat;
  position: relative;
}

.overlay{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  bottom: 0;
  right: 0;
  z-index: 1;
  background-color: rgba(3, 3, 3, 0.388);
  
}
.login_page_container .conent{
  position: absolute;
  top: 30px;
  left: 50%;
  z-index: 1;
  transform: translate(-50%);

}



