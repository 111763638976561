.elementor-container.flex-row-wrap {
  display: flex;
  flex-wrap: wrap;
}

.elementor-counter {
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-direction: column-reverse;
}

.elementor-counter .elementor-counter-number {
  flex-grow: var(--counter-number-grow, 0);
}

.elementor-counter .elementor-counter-number-wrapper {
  flex: 1;
  display: flex;
  font-size: 69px;
  font-weight: 600;
  line-height: 1;
  text-align: center;
}

.elementor-counter .elementor-counter-number-prefix {
  text-align: end;
  flex-grow: var(--counter-prefix-grow, 1);
  white-space: pre-wrap;
}

.elementor-counter .elementor-counter-number-suffix {
  text-align: start;
  flex-grow: var(--counter-suffix-grow, 1);
  white-space: pre-wrap;
}

.elementor-counter .elementor-counter-title {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  font-size: 15px;
  font-weight: 400;
  line-height: 2.5;
  width: 180px;
}

.elementor-counter span {
  font-size: 24px !important;
  text-align: center;
  
  display: inline-block;
}

.elementor-container.elementor-column-gap-default.flex-row-wrap {
  width: 100% !important;
  justify-content: space-evenly;
  padding: 20px;
  margin: 20px auto;
  background: black;
  color: white;
  margin-top: 0 !important;
}
