/* Accordion.css */
/* Accordion.css */

.accordion {
  width: 100%;
  /* max-width: 800px; */
  /* margin: 0 auto; */
  font-family: Arial, sans-serif;
}

.accordion-item {
  /* border: 1px solid #ccc; */
  /* border-radius: 8px; */
  margin-bottom: 10px;
  overflow: hidden;
  transition: all 0.3s ease;
  /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); */
}

.accordion-header {
  display: flex;
  align-items: center;
  padding: 15px;
  background-color: #000 !important;
  cursor: pointer;
  color: white !important;
  transition: background-color 0.3s ease;
}

.accordion-header:hover {
  background-color: #eaeaea;
}

.accordion-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 15px;
}

.accordion-title {
  font-size: 1.5em;
  margin: 0;
}

.accordion-content {
  background-color: #fff;
  height: 0;
  overflow: hidden;
  transition: height 0.3s ease;
}

.accordion-content.active {
  height: auto;
}

.accordion-body {
  padding: 20px;
}

.package-image {
  width: 100%;
  height: auto;
  margin-bottom: 15px;
  border-radius: 8px;
  object-fit: contain;
}

.package-details {
  margin-bottom: 15px;
}

.features {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.included-features,
.not-included-features {
  flex: 1;
}

.package-features {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.package-features li {
  font-size: 1em;
  margin-bottom: 10px;
}

.imgInclude {
  color: green;
}

.imgNotInclude {
  color: red;
}

.accordion {
  width: 100%;
  /* max-width: 1200px; */
  /* margin: 0 auto; */
  font-family: Arial, sans-serif;
}

.accordion-item {
  /* border: 1px solid #ccc; */
  /* border-radius: 8px; */
  margin-bottom: 10px;
  overflow: hidden;
  transition: all 0.3s ease;
  /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); */
}

.accordion-header {
  display: flex;
  align-items: center;
  padding: 15px;
  background-color: #f5f5f5;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.accordion-header:hover {
  background-color: #eaeaea;
}

.accordion-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 15px;
}

.accordion-title {
  font-size: 1.5em;
  margin: 0;
}

.accordion-content {
  background-color: #fff;
  height: 0;
  overflow: hidden;
  transition: height 0.3s ease;
}

.accordion-body {
  padding: 20px;
}

.package-image {
  width: 100%;
  height: auto;
  margin-bottom: 15px;
  border-radius: 8px;
}

.package-details {
  margin-bottom: 15px;
}

.features {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.included-features,
.not-included-features {
  flex: 1;
}

.package-features {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.package-features li {
  font-size: 1em;
  margin-bottom: 10px;
}

.package-images {
  display: flex;
  flex-wrap: wrap;
}

.package-images img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 8px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.accordion-title {
  color: white;
  font-weight: bold;
}

.package-image {
  width: 100px;
  height: auto;
  margin-bottom: 15px;
  border-radius: 8px;
}

.imgNotIclude {
  display: inline-block;
  padding: 4px;
  background-color: red;
  color: white;
}


.accordion-image {
    width: 120px;
    height: 70px;
    border-radius: 50%;
    margin-right: 15px;
    object-fit: contain;
}

.accodion-x {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background:linear-gradient(90deg, rgba(0, 0, 0, 0.752), rgba(0, 0, 0, 0.708)) ,url("https://res.cloudinary.com/duovxefh6/image/upload/v1720100766/images_jiez6s.jpg");
    gap: 19px;
    padding:20px;
}

.accordion-body .login_btn, .accordion-body .login_btn button, .accordion-body .login_btn, .accordion-body .login_btn button p {
  width: 100%;
  margin: 0;
}
.accordion-body .login_btn {
  margin-right: auto !important;
  display: flex;
  margin-top: 30px !important;
  margin-bottom: 10px !important;
  width: 100%;
}