@import url("https://fonts.googleapis.com/css2?family=Baloo+Bhaijaan+2:wght@400..800&family=Cairo+Play:wght@200..1000&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
* {
  margin: 0;
  padding: 0;
  font-family: "Roboto", system-ui !important;
  font-optical-sizing: auto;
  font-style: normal;
  line-height: 1.5em !important;
  box-sizing: border-box;
}

p {
  line-height: 1.8em !important;
}

