.custom-container {
    display: block;
    position: relative;
    padding-left: 42px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 16px;
    font-family: "Source Sans Pro", sans-serif;
    line-height: 1.6em;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  .custom-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  .custom-checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border-radius: 5px;
  }
  
  .custom-container:hover input ~ .custom-checkmark {
    background-color: #ccc;
    transition: .5s all;
  }
  
  .custom-container input:checked ~ .custom-checkmark {
    background-color: #34495e;
  }
  
  .custom-checkmark:after {
    content: "";
    position: absolute;
    display: none;
    transition: 0.5s all;
  }
  
  .custom-container input:checked ~ .custom-checkmark:after {
    display: block;
  }
  
  .custom-container .custom-checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }
  