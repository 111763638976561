/* SignatureComponent.css */

.signature-component {
    display: flex;
    /* flex-direction: column; */
    align-items: flex-start;
    flex-wrap: wrap;
    /* background-color: black; */
    /* padding: 20px 90px; */
    color: black;
  }
  
  .toggle-buttons button {
    margin: 10px;
    padding: 10px 20px;
    background-color: white;
    color: black;
    border: none;
    cursor: pointer;
  }
  
  .signature-pad {
    margin: 20px 0;
  }
  
  .sigCanvas {
    border: 1px solid black;
    background-color: white;
  }
  
  .upload-image input {
    margin: 20px 0;
  }
  
  .uploaded-img {
    max-width: 100%;
    height: auto;
  }
  
  .signature-component button {
    margin: 10px;
    padding: 10px 20px;
    background-color: white;
    color: black;
    border: none;
    cursor: pointer;
  }
  

  .first_section_container .fs_content, .first_section_container .fs_content>*{
    height:100vh !important;
  }


  .footer {
    bottom: 0;
    width: 100%;
    margin-top: 50px;
    padding: 20px calc(1.5rem / 2);
    /* position: absolute; */
    /* right: 0; */
    color: var(--bs-footer-color);
    left: var(--bs-sidebar-width);
    /* height: 60px; */
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.08);
    background-color: #80808036;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 15px;
}

.MeasurmentElements{
  flex-direction: column;
}
