.description-image-terms {
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: #000;
  color: #fff;
  padding: 20px;
  box-sizing: border-box;
}

.description {
  flex: 1;
  padding: 20px;
}

.image-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.image-container img {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
}

.terms {
  flex: 1;
  padding: 20px;
}

h2, h3 {
  color: #fff;
}

ul {
  /* list-style-type: none; */
  padding: 0;
}

ul li {
  margin-bottom: 10px;
}

ul li strong {
  color: #ffd700;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .description-image-terms {
    flex-direction: column;
  }
}
