/* TestimonialsSection.css */

.installation-section-container {
  padding: 10px 0 0px;
  background-color: black;
  /* transform: translateY(-400px); */
} 

.installation-section-container .section-title {
  color: white !important;
  position: relative;
  width: fit-content;
  margin: 0 auto 8vh !important;
}
.installation-section-container .section-title img {
width: 100px;
position: absolute;
}



.installation-section-container .section-title img:first-of-type {
  /* right: 350px; */
  transform: rotate(-80deg);
  top: -45px;
}

.installation-section-container .section-title img:first-of-type {
  right: -18% !important;
}

.installation-section-container .section-title img:last-of-type {
  left: -18% !important;
 top: 0px;
 transform: rotate(100deg);
}

.installation-section-container .section-title span {
  display: block;
  font-size: 1.4em;
  /* color: #666; */
}

.installation-section-container .section-title h2 {
  font-size: 2.5em;
  margin-top: 0.5em;
  color: white ;
}

.installation-section-container .section-title p {
  font-size: 1em;
  color: #444;
}

.installation-section-container .video-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  padding: 20px ;
}

.installation-section-container .video-background {
  background-color: #000;
  padding: 20px;
  /* border-radius: 8px; */
}

.installation-section-container .video-frame {
  width: 100%;
  /* max-width: 800px; */
  height: 550px;
  border: none;
}


.installation-section-container .video-background, .installation-section-container .video-background iframe {
  width: 100% !important;
  margin: auto;
}

.video-wrapper {
display: flex;
align-items: center;
gap: 10px;
}

.installation_container_custom {
max-width: 1000px;
width: 100%;
margin: 20px auto 0px;
}


@media (max-width:950px) {
  .installation-section-container .section-title img:first-of-type {
    right: 320px;
  }
  
  .installation-section-container .section-title img:last-of-type {
   left: 220px;
  }
}


@media (max-width:900px) {
  .installation-section-container .section-title img:first-of-type {
    right: 300px;
  }
  
  .installation-section-container .section-title img:last-of-type {
   left: 200px;
  }
}

@media (max-width:856px) {
  .installation-section-container .section-title img:first-of-type {
    right: 280px;
  }
  
  .installation-section-container .section-title img:last-of-type {
   left: 180px;
  }
}

@media (max-width:815px) {
  .installation-section-container .section-title img:first-of-type {
    right: 250px;
  
  }
  .installation-section-container .section-title img:last-of-type {
   left: 150px;
  }
}

@media (max-width:760px) {
  .video-wrapper {
    display: flex;
    flex-direction: column-reverse;
  }

  .video-wrapper p {
    max-width: 95%;
    width: 100%;
  }
}

@media (max-width:758px) {
  .installation-section-container .section-title img:first-of-type {
    right: 220px;
  
  }
  .installation-section-container .section-title img:last-of-type {
   left: 120px;
  }
}

@media (max-width:700px) {
  .installation-section-container .section-title img:first-of-type {
    right: 200px;
  
  }
  .installation-section-container .section-title img:last-of-type {
   left: 100px;
  }
}

@media (max-width:660px) {
  .video-wrapper p {
    max-width: 100%;
    width: 100%;
  }
}

@media (max-width:658px) {
  .installation-section-container .section-title img:first-of-type {
    right: 180px;
  
  }
  .installation-section-container .section-title img:last-of-type {
   left: 80px;
  }
}

@media (max-width:620px) {
  .installation-section-container .section-title img:last-of-type {
   left: 60px;
  }
}


@media (max-width:597px) {
  .installation-section-container .section-title img:first-of-type {
    right: 160px;
  
  }
  .installation-section-container .section-title img:last-of-type {
   left: 50px;
  }
}


@media (max-width:558px) {
  .installation-section-container .section-title img:first-of-type {
    right: 140px;
  }
  .installation-section-container .section-title img:last-of-type {
   left: 30px;
  }
}

@media (max-width:510px) {
  .installation-section-container .section-title img:first-of-type {
    right:100px;
  }
  .installation-section-container .section-title img:last-of-type {
   left: 10px;
  }
}

@media (max-width:470px) {
  .installation-section-container .section-title img:first-of-type {
    right:80px;
  }
  .installation-section-container .section-title img:last-of-type {
   left: -10px;
  }
}

@media (max-width:430px) {
  .installation-section-container .section-title {
    max-width: 300px;
    width: 100%;
    margin: auto;
  }

  .installation-section-container .section-title img:first-of-type {
    right: -10px;
  }
  .installation-section-container .section-title img:last-of-type {
   left: -30px;
   top: 70px;
  }
  
  .installation-section-container .video-background {
    padding: 10px;
  }

   .video-wrapper {
    padding: 10px ;
   }

   .video-wrapper p {
    width: 100%;
    max-width: 100%;
    font-size: 18px !important;
   }
}