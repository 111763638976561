.measurement-installation-section {
  background-image: url(../../../assets/images/111.png);
  width: 100% !important;
  height: 400px;
  background-repeat: no-repeat; 
  background-position: top;
  background-size: cover;
  padding: 10px ;
  display: flex;
  justify-content: center;
  align-items: center;
}
.measurement-installation-page{
  height:230vh !important
}
.proposal-container {
  padding: 0px 25px;
  margin-top: -190px;
}
canvas{
  height: 100%;
}

.enhancify-containter, .roofing-page-container{
  margin-top:-154px !important
}

.measurement-content-container {
  padding-bottom: 190px !important;
  margin-top:-54px
}

.warrantly-container {
  display: flex;
  align-items: center;
  gap: 20px;
  max-width: 505px;
  margin: -30px auto 0;
}

.warrantly-container img {
  width: 200px;
}

.warrantly-container p {
  font-size: 28px;
  color: white;
  font-style: italic;
}

.warrantly-container span {
  color: #E50414;
  font-weight: bold;
}


.measurement-installation-page-container {
  background:linear-gradient(90deg, rgba(0, 0, 0, 0.67), rgba(0, 0, 0, 0.642)),
  url("https://res.cloudinary.com/duovxefh6/image/upload/v1720087100/metal-and-composition-roofing-on-home-with-multiple-roof-types-qk93ssq2mivzs1r695dw5ymh0gie0f3xe5bzpd5sbw-qk94eu1uup1zyxqtkob0mefidnw6gyldr7xsqwhigc_vpbbin.jpg");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: auto;
  /* height: 900px; */
  text-align: center;
}

.measure-down-background img  {
  width: 100px;
  object-fit: cover;
  height: 100px;
  position: absolute;
}

.measure-down-background {
  position: relative;
}

.measure-down-background img:first-of-type {
  position: absolute;
  top: -40px;
  right: 300px;
  transform: rotate(-80deg);
}

.measure-down-background img:last-of-type {
  position: absolute;
  left: 300px;
  bottom: -60px;
  transform: rotate(100deg);
}

.measurement-content-container {
  padding-bottom: 240px;
}

.measurement-content-first-clippath-below {
  background-image: url(../../../assets/images/IMG_0708\ \(2\).PNG);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}
.measurement-imgs {
  background-image: url(../../../assets/images/rahme.png);
  display: flex;
  background-repeat: no-repeat;
  justify-content: center;
  align-items: center;
  background-size: 100% 100%;
  min-height: 150vh;
  position: relative;
  top: -255px;
  padding: 20px 0px;
}

.measurement-imgs .swiper-initialized {
  max-width: 830px;
  width: 100%;
}

.measurement-imgs img {
  width: 280px;
  height: 300px;
  object-fit: cover;
}

.measurement-imgs-container {
  max-width: 700px;
  width: 100%;
  margin: 10px auto;
  display: grid;
  grid-template-columns: repeat(3,1fr);
  /* display: flex;
  justify-content: space-between;
  align-items: center; */
  gap: 20px;
}

@media (max-width:1200px) {

  .measurement-imgs-container {
    max-width: 800px;
    width: 100%;
    margin: 10px auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
  }
}

@media (max-width:1189px) {
  .measure-down-background img:first-of-type {
    top: -40px;
    right: 260px;
  }
  
  .measure-down-background img:last-of-type {
    left: 260px;
    bottom: -60px;
  }
}

@media (max-width:1121px) {
  .measure-down-background img:first-of-type {
    top: -40px;
    right: 230px;
  }
  
  .measure-down-background img:last-of-type {
    left: 230px;
    bottom: -60px;
  }
}

@media (max-width:1100px) {
  .measurement-installation-section {
    height: 500px;
  } 

}

@media (max-width:1058px) {
  .measure-down-background img:first-of-type {
    right: 200px;
  }
  
  .measure-down-background img:last-of-type {
    left: 200px;
  }
}

@media (max-width:1050px) {
  .measurement-imgs {
    padding: 40px !important;
    /* min-height: 170vh; */
  }

  .measurement-imgs-container {
    padding-bottom: 20px;
  }
  
}

@media (max-width:1000px) {
  .measure-down-background img:first-of-type {
    right: 150px;
  }
  
  .measure-down-background img:last-of-type {
    left: 150px;
  }

  .measurement-imgs {
    min-height: 120vh;
  }

  .measurement-imgs img {
    margin-top: 30px !important;
  }
}


@media (max-width:895px) {
  .measure-down-background img:first-of-type {
    right: 130px;
  }
  
  .measure-down-background img:last-of-type {
    left: 130px;
  }
}

@media (max-width:890px) {
  .measurement-installation-section {
    height: 400px;
  }
  
  .warrantly-container img {
    width: 150px;
  } 

  .warrantly-container p {
    font-size: 24px;
  }
}

@media (max-width:850px) {
  .measure-down-background img:first-of-type {
    right: 100px;
  }
  
  .measure-down-background img:last-of-type {
    left: 100px;
  }
}


@media (max-width:800px) {
  .measure-down-background img:first-of-type {
    right: 80px;
  }
  
  .measure-down-background img:last-of-type {
    left: 80px;
  }

  .measurement-imgs {
    /* min-height: 200vh !important; */
  }
}


@media (max-width:754px) {
  .measure-down-background img:first-of-type {
    right: 60px;
  }
  
  .measure-down-background img:last-of-type {
    left: 60px;
  }


}

@media (max-width:713px) {
  .measure-down-background img:first-of-type {
    right: 40px;
  }
  
  .measure-down-background img:last-of-type {
    left: 40px;
  }
}

@media (max-width:690px) {
  .measurement-installation-section {
    height: 350px;
  } 

  .warrantly-container {
    gap: 10px;
  }
}

@media (max-width:675px) {
  .measure-down-background img:first-of-type {
    right: 5px;
  }
  
  .measure-down-background img:last-of-type {
    left: 10px;
  }

  .measurement-imgs {
    min-height: 150vh !important;
  }
}


@media (max-width:635px) {
  .warrantly-container img {
    width: 120px;
  } 

  .warrantly-container p {
    font-size: 20px;
  }
}

@media (max-width:613px) {
  .measure-down-background img:first-of-type {
    right: 0px;
    top: -70px;
  }
  
  .measure-down-background img:last-of-type {
    left: 10px;
  }

  .measure-down-background p {
    max-width: 400px;
    width: 100%;
  }
}

@media (max-width:600px) {
  .measurement-installation-section {
    height: 300px;
  } 
}

@media (max-width:700px) {
  /* .measurement-imgs {
    min-height: 100vh !important;
  } */
}


@media (max-width:530px) {
  .measurement-installation-section {
    height: 250px;
  } 

  .warrantly-container p {
    font-size: 18px;
  }
}

@media (max-width:506px) {
  .measure-down-background p:first-of-type {
    font-size:20px !important;
    max-width: 300px;
    width: 100%;
  }   

  .measure-down-background p:last-of-type {
    font-size: 15px !important;
    max-width: 300px;
    width: 100%;
  }
}



@media (max-width:440px) {
  .measurement-installation-section {
    height: 200px;
  } 

  .warrantly-container p {
    font-size: 15px;
  }

  .measurement-imgs {
    min-height: 160vh;
  }

  .measurement-imgs img {
    margin-top: 20px !important;
  }
}


@media (max-width:410px) {
  .measure-down-background img:first-of-type {
    right: 0px;
    top: -60px;
  }
  
  .measure-down-background img:last-of-type {
    left: 10px;
  }
}

@media (max-width:430px) {
  .warrantly-container img {
    width: 80px;
  }
  .measure-down-background img:first-of-type {
    right: -10px;
    top: -80px;
  }
  
  .measure-down-background img:last-of-type {
    left: 10px;
  }
  
  .measurement-imgs {
    background-image: url(../../../assets/images/rahme2.png);
    min-height: 80vh !important;
    top: -250px !important; 
  }


}

@media (max-width:400px) {
  
  .measurement-imgs {
    min-height: 130vh !important;
  }
}

.measurement-testimonial-item {
  display: flex;
  justify-content: center;
  align-items: center;
}


/* width */
::-webkit-scrollbar {
  width: 0 !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}