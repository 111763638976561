.roofing-page-container {
    padding: 0px 30px;
}

.roofing-header {
    max-width: 400px;
    margin: 0px auto;
}

.roofing-header img {
    width: 100px;
    object-fit: cover;
}

.roofing-header h3 {
    font-size: 27px;
    font-weight: bold;
    text-align: center;
    color: black;
}


.roofing-header img:first-of-type {
    position: relative;
    left: 280px;
    top: 60px;
    transform: rotate(-80deg);
}


.roofing-header img:last-of-type {
    position: relative;
    top: -60px;
    left: 20px;
    transform: rotate(100deg);
}

.roofing-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* gap: 10px; */
    border-radius: 30px;
    border:  1px solid #C22E2C;
    max-width: 800px;
    width:100%;
    margin: -20px auto;
    overflow: hidden;
}

.roofing-list li:not(:first-of-type){
    border-top: 1px solid #C22E2C;
}

.roofing-list li {
    font-size: 18px;
    padding: 7px 10px;
    text-align: center;
    margin: auto 0px;
    font-weight: bold;
    cursor: pointer;
}

.roofing-list li.active{
    background-color: black;   
    color: white;
    height: 100%;
}

.roofing-content {
    background-color: white;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 20px;
    max-width: 800px;
    width: 100%;
    margin: 40px auto;
}

.roofing-content-header p{
   font-size:23px;
   color: black;
   margin: 0px;
    padding: 0px;
}

.roofing-content-header h2 {
    color: #C22E2C;
    font-size: 40px;
    font-weight: bold;
    margin: 0px;
    padding: 0px;
    font-weight: bold;
}

.roofing-content-list-type {
    display: flex;
    flex-direction: column;
    margin: 20px 0px;
}

.roofing-content-list-type li {
    display: flex;
    gap: 5px;
    margin: 0px;
    padding: 0px;
    font-size: 17px;
}

.roofing-content-list-type li .color  {
    color: #C22E2C;   
}

.roofing-content-list-stars {
    display: flex;
    flex-direction: column;
    margin: 20px 0px;
}

.roofing-content-list-stars p {
    margin: 0px;
    padding: 0px;
    font-size: 16px;
    font-weight: 500;
}

.roofing-content-list-costs h5{
   color: #C22E2C;
}

.roofing-content-list-costs ul {
    display: flex;
    flex-direction: column;
    margin: 0px 30px;
    /* padding: ; */
}

.roofing-content-list-costs ul li {
    font-size: 16px;
    list-style-type: disc !important;
}

.roofing-content-list-costs ul li::marker {
    color: #C22E2C !important;
    list-style-type: disc !important;
}

.roofing-images {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    margin: 30px 0px;
}

.roofing-images img {
    width: 50px;
    height: 50px;
}

.roofing-content-investment {
    margin: 20px auto;
    display: flex;
    flex-direction: column;
    gap: 4px;
    max-width: 800px;
    width: 100%;
}

.roofing-content-investment h3 {
    color: #C22E2C;
    font-weight: 600;
    font-size: 20px;
}

.roofing-content-investment div {
    background-color: #C22E2C;
    height: 0.5px;
    width: 100%;
}

.roofing-content-investment p {
    font-size: 17px;
    color: black;
}

.roofing-content-investment h2 { 
    font-size: 26px;
    font-weight: 600;
    color: #C22E2C;
    margin: 10px 0px;
}

.roofing-switch-btns-container {
   max-width: 700px;
   margin: 10px auto;
   display: flex;   
}

.roofing-switch-btns-container div{
    padding: 10px 40px;
    border-radius: 50px;
    width: 100%;
}

.roofing-switch-btns-container div:first-of-type {
    background-color: #C22E2C;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    left: 35px;
}

.roofing-switch-btns-container div:last-of-type {
    background-color: white;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
} 

.roofing-switch-btns-container div span {
    margin-left: 30px;
}

.roofing-switch-btns-container img {
    width: 160px;
    object-fit: cover;
}

.switch-btns-text {
  font-size: 23px;
}

.switch-btns-text span:first-of-type {
    font-style: italic;
}

.switch-btns-text span:last-of-type {
    font-size: 23px;
    font-weight: bold;
}

.roofing-paragraph {
    max-width: 700px;
    width: 100%;
    font-size: 16px;
    color: black;
    font-style: italic;
    margin: 0px auto;
}

@media (max-width:500px) {
    .roofing-page-container .rowDiv {
        flex-direction: column-reverse;
    } 

    .roofing-page-container .rowDiv p {
        width: 100% !important;
    }
}

@media (max-width:450px) {    
    .roofing-header img:last-of-type {
        left: -10px;
        top: -50px;
    }

    .accordion-roofing-img  {
        width: 150px !important;
    }
}

@media (max-width:400px) {
    .roofing-header img:first-of-type {
        position: relative;
        left: 250px;
        top: 60px;
        transform: rotate(-80deg);
    }
}

@media (max-width:390px) {
    .roofing-header img:last-of-type {
        left: -25px;
        top: -50px;
    }
}

.login_btn button.btn-success{
    background-color: green;
}

.gutters.proposal-content-images {
    gap: 10px;
    display: grid;
    grid-template-columns: repeat(3, 280px);
    margin: 10px 0;
}

.gutters.proposal-content-images img {
    width: 280px;
    height: 300px;
    cursor: pointer;
    object-fit: cover;
    border-radius: 9px;
}


.selectedImage{
    filter:saturate(0);
}