.proposal-container {
  padding: 0px 25px;
}

.proposal-agreement-header {
  max-width: 300px;
  width: 100%;
  margin: 10px auto 0px;
}
.proposal-agreement-header p {
  color: black;
  font-size: 25px;
  text-align: center;
}

.proposal-agreement-header p span {
  color: #e50414;
  font-weight: bold;
}

.proposal-agreement-header img {
  width: 100px;
  position: relative;
}

.proposal-agreement-header img:first-of-type {
  left: 100%;
  top: 55px;
  transform: rotate(-80deg);
}

.proposal-agreement-header img:last-of-type {
  top: -60px;
  left: -100px;
  transform: rotate(90deg);
}

.proposal-content-container {
  max-width: 800px;
  width: 100%;
  margin: 0px auto 10px;
}

.proposal-content-container h3 {
  color: #e50414;
  font-size: 22px;
  font-weight: bold;
  margin: 0px;
  padding: 0px;
}

.proposal-content-container h3 span {
  color: black;
}

.proposal-content-container p {
  width: 70%;
  font-size: 16px;
  color: black;
  margin: 0px;
  padding: 0px;
}

.proposal-content-container form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 20px 0px;
}

.proposal-content-container form div {
  display: flex;
  flex-direction: column;
}

.proposal-content-container form div label {
  font-style: italic;
  font-size: 16px;
}

.proposal-content-container form label span {
  color: #e50414;
}

.proposal-content-container form div input {
  padding: 7px 20px;
  border-radius: 20px;
  border: 1px solid #e50414;
  outline: none;
}

.proposal-investment-form {
}

.proposal-investment-form p {
  width: 100% !important;
}

.proposal-investment-form h4 {
  color: #e50414;
  font-size: 20px;
  font-weight: bold;
  margin-top: 30px;
  font-style: italic;
}

.proposal-investment-form form input {
  background-color: #cccccc6d;
  border: 1px solid #cccccc6d !important;
}

.proposal-content-container button {
  background-color: #d13731;
  color: white;
  border: 1px solid #d13731;
  border-radius: 6px;
  padding: 7px 25px;
  margin: 20px 0px;
}

.proposal-date-signed {
  margin: 30px 0px;
  display: flex;
  flex-direction: column;
}

.proposal-date-signed label {
  font-style: italic;
  font-size: 16px;
}

.proposal-date-signed input {
  width: fit-content;
  border-radius: 20px;
  background-color: #cccccc6d;
  border: 1px solid #cccccc6d !important;
  padding: 5px 10px;
}

.proposal-paypal-img img {
  width: 200px;
}

.paypal-powered-by {
  display: none !important;
}

.section-title,
.measure-down-background {
  width: fit-content;
  position: relative;
  margin: auto;
}

.testimonial-logo:last-of-type,
.measure-down-background img:last-of-type {
  right: 100% !important;
  left: unset;
}
.measure-down-background img:last-of-type {
  bottom: 0px;
}
.testimonial-logo:first-of-type,
.measure-down-background img:first-of-type {
  left: 100% !important;
  right: unset;
}

.measure-down-background img:first-of-type{
  left: 96% !important;
}

.measure-down-background img:last-of-type{
    right: 96% !important;
  }

.testimonial-logo {
  width: 50px;
  object-fit: cover;
  height: 110px;
}
