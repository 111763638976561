.login_container{
  padding: 0px;
  border-radius: 10px;
  background-color: #fff;
  z-index: 10;
 width: min(400px, 100%);
  padding: 30px 15px;
}

.login_container .login_continer_content{
  display: flex;
  /* align-items: center; */
  flex-direction: column; 
  gap: 30px
}

.login_page_container .conent{
  width:90%;
  display: flex;
  justify-content: center;
}
.login_container .login_continer_content h5{
  font-size: 15px;
}

.login_container .login_continer_content input{
  border: none;
  outline: none;
  padding: 5px 10px;
  border-radius: 4px;
  width: 100%;
  border: 1px solid #222222b1;
  transition: .1s ease;
}

.login_container .login_continer_content input:focus{
  border-color:  var(--app-color);
  border-width: 2px;
}

.login_container .remeber_me{
  display: flex;
  align-items: center;
  gap: 10px;
}



.login_btn{
  text-align: center;
  margin-top: 10px;
}

.login_btn button{
  all: unset;
  padding: 12px 55px;
  border: none;
  outline: none;
  background-color: #e31f26;
  color: #fff;
  font-weight: 800;
  border-radius: 4px;
  cursor: pointer;
  transition: .3s ease;
}

.login_btn button:hover{
  /* background-color: var(--app-dark-color); */
}

.remeber_me.rowDiv{
  display: flex !important;
  align-items: center;
}

.accordion-body .login_btn {
  margin-right: auto !important;
  display: flex;
  margin-top: 30px;
}